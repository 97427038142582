import React, {useEffect, useState} from 'react';
import {Button, Divider, Modal} from 'antd'
import {InstagramOutlined} from '@ant-design/icons';
import * as images from "../../assets/image"
import './style.scss'


function Footer(props:any) {
  const [showModal, setShowModal] = useState(true)
  const [showContactUsModal, setShowContactUsModal] = useState(true)

  useEffect(() => {
    handlePwa()
  }, [])

  const handlePwa = () => {
    let deferredPrompt: any;
    let installBtn: any = document.getElementById('installBtn')
    let btnContactUsPopUp: any = document.getElementById('btnContactUsPopUp')

    
    const installModal: any = document.querySelector('.install-popup-wrap');
    const contactusModal: any = document.querySelector('.contactus-popup-wrap');
  

 

    if (isIos() && !installedApp()) {
      setTimeout(() => {
        installModal.classList.remove('hide')
      }, 10000)
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // in ios this button is visible always. we show in other platform if it be installable
      installBtn.style.display = "block"
      if (isAndroid() && !installedApp()) {
        setTimeout(() => {
          installModal.classList.remove('hide')
        }, 10000)
      }
    });


    installBtn.addEventListener('click', async () => {
      // Hide the app provided install promotion
      if (!isIos()) {
        installBtn.style.display = "none"
      }
      // Show the install prompt
      deferredPrompt?.prompt();
      // Wait for the user to respond to the prompt
      const {outcome} = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
    })

    window.addEventListener('appinstalled', () => {
      // Hide the app-provided install promotion
      if (!isIos()) {
        installBtn.style.display = "none"
      }
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt = null;
      // Optionally, send analytics event to indicate successful install
      console.log('PWA was installed');
    });


    btnContactUsPopUp.addEventListener('click', async () => {
    try {
      // contactusModal.style.display = "none"
      // contactusModal = document.querySelector('.contactus-popup-wrap');

      contactusModal.classList.remove('hide')
    }
    catch (ee){
      console.log(ee)
    }    


      //setShowContactUsModal(true)
    });


  }

  const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false
      } else {
        return true
      }
    }
  }

  const installedApp = () => (window.matchMedia('(display-mode: standalone)').matches);


  return (
    <div id="footer" style={props.show ? {display: 'block'} : {display: "none"}}>
      <div className="logos" style={{marginBottom: 15}}>
        <img src={images.magze_fa} style={{height: 70, margin: '0 0 15px 15px'}}/>
        <h1>مجله گالری زندگی</h1>
      </div>

      <div style={{textAlign: "center"}}>
        <p>
          اپلیکیشن هوشمندی که مجموعه دست‌سازهای ایرانی رو گرد هم میاره
          و خریدار رو به بازار مستقل آنلاین وصل میکنه.
        </p>
      </div>

      <div className="flex-center">
        <div>
          <Modal
            width={350} 
            footer={null}
            mask={false}
            wrapClassName="install-popup-wrap hide"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
          >
            <div className="install-popup">
              <img src={images.magziBrand} className="logo"/>
              <h2> وب اپلیکیشن مگ‌زی را به صفحه اصلی موبایل خود اضافه کنید.</h2>
              <Divider/>
              {isIos() ?
                <>
                  <div>
                    <img src={images.UpArrow} style={{width: 20, margin: 10}}/>
                    <p>۱ -در نوار پايين روی دکمه Share بزنید.</p>
                  </div>
                  <div>
                    <img src={images.plus} style={{width: 20, margin: 10}}/>
                    <p> ۲ -در منوی باز شده، در قسمت پايين، گزینه Add to Home Screen را انتخاب کنید </p>
                  </div>
                  <div>
                    <span style={{color: 'blue', margin: 10}}> Add </span>
                    <p> ٣ -در مرحله بعد در قسمت بالا روی Add بزنید.</p>
                  </div>
                  <div>
                    <Button
                      className="btn-primary" style={{width: 120, marginTop: 30}}
                      onClick={() => setShowModal(false)}
                    >
                      متوجه شدم
                    </Button>
                  </div>
                </> : <div>
                  <Button
                    id="installBtn2"
                    className="btn-primary"
                    style={{marginTop: 30}}
                    onClick={isIos() ?
                      () => {
                        setShowModal(false)
                      }
                      : () => document.getElementById('installBtn')?.click()}
                  >
                    نصب وب اپلیکیشن
                  </Button>
                </div>
              }

            </div>


          </Modal>

         


        </div>
        <div>

        <Modal width={350}
            footer={null}
            mask={false}
            wrapClassName="contactus-popup-wrap hide"
            visible={showContactUsModal}
            onOk={() => setShowContactUsModal(false)}
            onCancel={() => setShowContactUsModal(false)}>
             <div style={{textAlign:'center'}}>
                  <p>
                  آدرس: تهران، خ قائم مقام، ک مگنولیا، پ ۳۳
                    <br/>
                  <br/>
                    تلفن: ۸۸۸۶۳۲۲۷-۰۲۱
                    <br/>
                    <br/>
                    <br/>
                  
                    <a href="mailto:info@magziapp.com"  >
                    info@magziapp.com  
                    </a>
                  </p>
        </div>
          </Modal>

        </div>

        {
          isIos() ?
            <>
              <span id="installBtn"/>
              <Button
                onClick={() => setShowModal(true)}
              >
                وب اپلیکیشن
              </Button>
          </>
          : <Button
            id="installBtn"
            style={{display: 'none'}}
          >
            وب اپلیکیشن
          </Button>
        }

        {/*{isAndroid() &&*/}
        {/*<a href="../../assets/image/magze_brand_red_bg.svg" download>*/}
        {/*  <Button style={{display: "block", marginRight: 15}} icon={<AndroidOutlined/>}>*/}
        {/*    <span>اپ اندروید</span>*/}
        {/*  </Button>*/}
        {/*</a>*/}
        {/*}*/}

      </div>

      <div className="flex-center social">
      <Button id="btnContactUsPopUp"
      style={{border: 'none'}}
                onClick={() => setShowContactUsModal(true)}
              >
               تماس با ما
              </Button> 
        <div>
          <a href="https://www.instagram.com/magzi.app/?utm_medium=copy_link"> 
          <label className='hide'> تماس با ما: </label>
            <InstagramOutlined/></a>
        </div>
        {/*<MailOutlined/>*/}
      </div>
        

         
{/* 
      <div className="flex-center  " >
        <div>
           <p>
           آدرس: تهران، خ قائم مقام، ک مگنولیا، پ ۳۳
           <br/>
            تلفن: ۸۸۸۶۳۲۲۷-۰۲۱
            <br/>
          
             <a href="mailto:info@magziapp.com" style={{color:'white'}}>
            info@magziapp.com  
             </a>
           </p>
        </div>
        
      </div> */}




    </div>
  );
}

export default Footer;
