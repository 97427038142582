import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Skeleton} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import Video from './assets/play.svg';
import * as images from '../../assets/image'
import './style.scss'
import Ajax from "../../api/ajax";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/reducer/authSlice";
import {Cookies} from 'react-cookie';
 

function ImageItem(props: any) {
  const history = useHistory();
  const {auth} = useSelector(authReducer);
  const [liked, setLiked] = useState(props.item.post_liked)
  const [update, setUpdate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const variant = props.item.medias[0] ? props.item.medias[0].variant : null
  const {style, item} = props;

  const [sourcePage, setSourcePage] = useState(props.source)

  const setLastCookie = ()=>{
    console.log("sourcePage ",sourcePage)

    if(sourcePage != undefined)
    {
      const cookie = new Cookies();
      //console.log(cookie)
      cookie.set("source_page",sourcePage,{path:'/'})


    }

  }

  const goToPost = () => {
    setLastCookie()

    if (props.onClick){
      props.onClick()

    }
    window.scrollTo(0, 0)
  }

  const handleLike = ()=> {
    if (auth.status){
      setLikeLoading(true)
      new Ajax({
        success: (res) => {
          setLiked(!liked)
          setUpdate(!update)
          setLikeLoading(false)
        },
        error: (error) => {
          setLikeLoading(false)
        }
      }).setUrl('like/like-post/')
        .setMethod('POST')
        .setPrivate()
        .setData({post_id: item.post_id, is_liked: !liked})
        .send();
    } else {
      props.setShowLogin(true)
    }
  }

  return (
    <div
      className={props.collection ? "image-item collection-img" : "image-item"}
      style={ style ? style: {}}
    >
      {item.medias[0] && item.medias[0].media_type === 1 &&  /* اینجا باید حتما شناسه ی ویدیو باشه چون یه آیکون ویدیو نشون میده */
        <img style={{width: 48, margin: 20}} alt="" src={Video}/>
      }

      {variant &&
        window.innerWidth < 576 ?
        <Link to={`/post/${props.item?.post_id}`}>
          <img
          alt={` - مگزی${props.content}`}
          src={variant[3]?.url ? variant[3]?.url : variant[2]?.url ? variant[2].url :variant[0].url}
          className="image"
          onClick={goToPost}
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(false)}
          style={!loaded ? {display: "none"} : {}}
          />
        </Link>
        : <a href={`/post/${props.item?.post_id}`} target="_blank">
          <img
            alt={` - مگزی${props.content}`}
            src={variant[3]?.url ? variant[3]?.url : variant[2]?.url ? variant[2].url :variant[0].url}
            className="image"
            onClick={goToPost}
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(false)}
            style={!loaded ? {display: "none"} : {}}
          />
        </a>
      }
      {/*{props.item.medias.length > 1 && <img alt="gallery" src={galleryIcon} className="image-gallery-icon"/>}*/}
      <span className="like">
        {
          likeLoading ? <LoadingOutlined />
          : <img
            src={liked ? images.heartRed : images.heart}
            className="like-icon"
            onClick={handleLike}
          />
        }
      </span>
    </div>
  );
}

export default ImageItem;
