import React, {useEffect, useState} from 'react';
import Ajax from "../../api/ajax";
import './style.scss'
import FullPageLoading from "../../components/fullPageLoading/fullPageLoading";
import IranianHandMade from "./modules/iranian-hand-made"
import Favorites from "./modules/favorites"
import MagzeOffers from "./modules/magze-offers"
import IranianStyles from "./modules/iranian-styles"
import ForGift from "./modules/for-gift"
import MadeOf from "./modules/mede-of"
import GoodFor from "./modules/good-for"
import {useSelector} from "react-redux";
import {authReducer} from "../../store/reducer/authSlice";

function Home(props: any) {
  const {auth} = useSelector(authReducer);
  const [loading, setLoading] = useState(false);

  return (
    <div id="home" className="relative">
      <FullPageLoading show={loading}/>
      <IranianHandMade onSearch={props.onSearch} />
      {auth.status && <Favorites onSearch={props.onSearch} setShowLogin={props.setShowLogin}  />}
      <MagzeOffers  onSearch={props.onSearch} setShowLogin={props.setShowLogin} setOffset={props.setOffset} />
      <IranianStyles  onSearch={props.onSearch} setShowLogin={props.setShowLogin}  />
      <ForGift  onSearch={props.onSearch} setShowLogin={props.setShowLogin}  />
      <MadeOf  onSearch={props.onSearch} setShowLogin={props.setShowLogin}  />
      <GoodFor onSearch={props.onSearch} setShowLogin={props.setShowLogin}  />
    </div>
  );
}

export default Home;
