import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import 'antd/dist/antd.css';
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import '../assets/style/overrideLib.scss'
import '../assets/style/globalStyle.scss'
import Landing from '../pages/landing/landing';
import NotFound from '../pages/notFound/notFound';
import {Alert} from "antd";




const AppRooter =() => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  useEffect(() => {
    window.addEventListener("offline",
      () => setNetwork(window.navigator.onLine)
    );
    window.addEventListener("online",
      () => setNetwork(window.navigator.onLine)
    );
  });

  return (
<>
    {!isOnline && <Alert style={{position: "fixed", width: '100%',padding: '25px 10px',  zIndex:100, fontSize:20}} message={<span style={{fontSize: 16}}>اتصال اینترنت را بررسی کنید</span>} type="error" showIcon/>}
    <Router>
      <Switch>
        <Route path="/" component={Landing}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    </Router>
  </>
  );
}


export default AppRooter;
