import * as React from 'react'
import './style.scss'
import {loadingIcon} from '../../assets/image'

interface IProps {
  loading: boolean,
  type?: string,
  style?: any,
}

const Loading: React.FC<IProps> = ({loading, type, style}) => {
  return (
    <div>
      {loading ? type === 'inline' ?
        <div className="inlineLoading">
          <img src={loadingIcon} style={style}/>
        </div> : (
          <div className="fullPageLoading">
            <img src={loadingIcon} style={style}/>
          </div>
        ) : null}
    </div>
  )
}

export default Loading
