import React, {useEffect, useState} from 'react';

import './style.scss'
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import {Col, Row, Tabs} from "antd";
import ImageItem from "../../../../components/imageItem/imageItem";
import Ajax from "../../../../api/ajax";
import {Collection_API} from "../../../../api/constant";
import {CaretLeftOutlined} from "@ant-design/icons";
import SwiperCarousel from "../../../../components/swiper";
import {SwiperSlide} from "swiper/react";


function MadeOf(props: any) {
  const [loading, setLoading] = useState(false);
  const tabs = ['سفال و سرامیک', 'چوب', 'مس', 'طلا', 'نقره', 'الیاف طبیعی', ]
  const [active, setActive] = useState('سفال و سرامیک');
  const [items, setItems] = useState([]);

  useEffect(() => {
    getCollection(active)
  }, []);

  const changeTab = (key: any) => {
    console.log(key);
    setActive(key)
    getCollection(key)
  }

  const getCollection = (name: string) => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setItems(res.data.data.posts)
      },
      error: (error) => {
        setLoading(false)
      }
    }).setUrl(Collection_API(name, 6))
      .setMethod('GET')
      .send();
  }

  return (
    <div id="MadeOf" className="relative">
      <FullPageLoading show={loading}/>
      <div className="home-section" style={{background: '#fbecca'}}>
        <h2 className="home-title">ساخته شده از</h2>
          <Tabs activeKey={active} onChange={changeTab}>
            {
              tabs.map((item: string) =>
                <Tabs.TabPane tab={item} key={item}>
                  {window.innerWidth < 576 ?
                    <SwiperCarousel>
                      {items.map((item) =>
                        <SwiperSlide>
                          <div className="category-item">
                            <ImageItem
                              setPost={props.setPost}
                              item={item}
                              setShowLogin={props.setShowLogin}
                            />
                          </div>
                        </SwiperSlide>
                      )}
                    </SwiperCarousel>
                    : items.length === 6 &&
                    <Row className="relative">
                      <FullPageLoading show={loading}/>
                      <Col xs={12} sm={8} md={8} lg={8}>
                        <ImageItem
                          setPost={props.setPost}
                          item={items[0]}
                          style={{height: window.innerWidth < 1500 ? 390 : 430}}
                        />
                      </Col>
                      <Col xs={12} sm={8} md={8} lg={8} style={{height: 400}}>
                        <Row>
                          {items.slice(2, 6).map((item: any, index: any) => (

                            <Col xs={12} sm={12} md={12} lg={12}>
                              <ImageItem
                                setPost={props.setPost}
                                item={item}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col xs={12} sm={8} md={8} lg={8}>
                        <ImageItem
                          setPost={props.setPost}
                          item={items[1]}
                          style={{height: window.innerWidth < 1500 ? 390 : 430}}
                        />
                      </Col>
                    </Row>
                  }

                </Tabs.TabPane>
              )
            }
          </Tabs>
        <span
          className="see-more"
          onClick={() => {
            props.onSearch(active, 0, 1)
          }}
        >
          بیشتر ببینید <CaretLeftOutlined/>
        </span>
      </div>
    </div>
  );
}

export default MadeOf;
