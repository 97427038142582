import React, {useEffect, useState} from 'react';
import {Col, Empty, Pagination, Row, Dropdown, Menu} from "antd";
import Notification from "../../components/Notification/Notification";
import copy from 'copy-to-clipboard';
import ImageItem from "../../components/imageItem/imageItem";
import { EllipsisOutlined } from '@ant-design/icons';
import './style.scss'
import {useHistory} from "react-router-dom";
import Loading from "../../components/Loading";
import LazyLoad from "react-lazyload";


function SearchList(props: any) {


  const handleScroll = () => {
    if (window.location.pathname.includes('/list')) {
      const position = window.pageYOffset;
      props.setScrollPosition2(position);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const onChange = (page: any, pageSize: any) => {
    props.onSearch(undefined, page - 1)
  }

  return (
    <div id="SearchList" className="relative" style={{minHeight: 'calc(100vh - 100px)'}}>
      <Loading loading={props.loading} />
      {!props.loading && props.list && props.list.length > 0
        ?
        <>
          <Row >
            {props.list.map((item: any) => (
              <Col xs={12} sm={8} md={8} lg={6} xl={4}
                   className="image-container"
                   >
                <LazyLoad once height={130}>
                <ImageItem
                  item={item}
                  setShowLogin={props.setShowLogin}
                />
                </LazyLoad>
                {/*<Dropdown*/}
                {/*  overlay={<Menu>*/}
                {/*    <Menu.Item>*/}
                {/*      <span onClick={()=> history.push(`/publisher/${item.publisher?.publisher_id}`)}>مشاهده فروشگاه</span>*/}
                {/*    </Menu.Item>*/}
                {/*    <Menu.Item>*/}
                {/*      <span onClick={()=> {*/}
                {/*        Notification('info','لینک پست کپی شد','');*/}
                {/*        copy(`https://magziapp.com/post/${item?.post_id}`)*/}
                {/*      }}>اشتراک گذاری</span>*/}
                {/*    </Menu.Item>*/}
                {/*  </Menu>}*/}
                {/*  placement="topRight">*/}
                {/*  <EllipsisOutlined />*/}
                {/*</Dropdown>*/}
              </Col>
            ))}
          </Row>
          <Pagination
            className="pagination"
            showSizeChanger={false}
            current={props.offset + 1}
            defaultPageSize={60}
            onChange={onChange}
            total={props.total}
          />
        </>

        : props.list.length === 0
          ? <div className="no-data flex-center"><Empty description={props.message ? props.message : "نتیجه ای یافت نشد"} /></div>
          : null
      }

    </div>

  );
}

export default SearchList;
