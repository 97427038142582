import React, {useEffect, useState, useRef} from "react";
import {useHistory} from 'react-router-dom';
import {Input, Menu, Dropdown, Divider} from 'antd';
import {LeftOutlined, HeartOutlined, UserOutlined, RightOutlined, LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import {authReducer} from "../../store/reducer/authSlice.js";
import {useSelector} from "react-redux";
import * as images from '../../assets/image'
import Ajax from "../../api/ajax";
import './style.scss';

const {SubMenu} = Menu;

function MainSearch(props: any) {
  const history = useHistory();
  const inputRef = React.useRef<any>(null)
  const {auth} = useSelector(authReducer);
  const [value, setValue] = useState(history.location.pathname === '/list' ? props.word : null);
  const [category, setCategory] = useState([]);
  const [mobileCategory, setMobileCategory] = useState([]);
  const [parent, setParent]:any = useState({});
  const [current, setCurrent] = useState(props.word);
  const [mobileMode, setmobileMode] = useState(window.innerWidth < 576);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(0);
  const {setShowLogin, setShowSignUp} = props
  useEffect(() => {
    getCategory()
    setTimeout(() => {
      handleInstagramBrowser()
    }, 2000);
  }, []);

  useEffect(() => {
    setValue(props.word)
  }, [props.word]);

  const onSearch = (value: string, type: number = 0) => {
    if (value) {
      setMobileMenuOpen(false)
      props.setOffset(0)
      props.setType(type)
      props.onSearch(value, 0, type);
      inputRef.current!.blur()
    }
  };

  const handleClick = (e: any) => {
    setCurrent(e.key)
  };

  const isInstagramBrowser = () => {
    const isInsta = window.navigator.userAgent.includes("Instagram");
    return isInsta;
  }
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  const handleInstagramBrowser = ()=>{
      if(!isInstagramBrowser())
      {
        console.log('not instagram')
        return
      }
      console.log('instagram - never seen !')
      var s = document.getElementById('search');
      var ul =  s?.childNodes[1] as HTMLElement;
      var nodes = ul?.childNodes ; 
      var ln = nodes?.length || 0;
      if(ln > 0 && nodes)
      {
        var changedUlLi = false 
        for(var i=0;i < ln; i++){
            var n = nodes[i] as HTMLElement;
            
            if(n.style.overflowY != 'hidden'){
              changedUlLi = true;

              n.style.height = '0px';
              n.style.overflowY = 'hidden';
              n.style.pointerEvents = 'none';
              n.style.position = 'absolute';
            }
        }

        if(changedUlLi)
        {
          //ul = ul as HTMLElement;
          ul.style.height = '0px'
        }

      }
      

  }



  const handleClickMobile = (item: any) => {
    setParent(item)
    setCategoryLoading(item.id)
    new Ajax({
      success: (res) => {
        setMobileCategory(res.data?.sub_category_list)
        setCategoryLoading(0)
      },
      error: (error) => {
        setCategoryLoading(0)
      }
    }).setUrl(`category/sub-category-list/${item.id}/`)
      .setMethod('GET')
      .send();
  };

  const getCategory = () => {
    if (mobileMode) {
      new Ajax({
        success: (res) => {
          setParent({})
          setMobileCategory(res.data?.parent_category_list)
        },
        error: (error) => {
          console.log('xxx', error.response)
        }
      }).setUrl('category/parent-category-list/')
        .setMethod('GET')
        .send();
    } else {
      new Ajax({
        success: (res) => {
          setCategory(res.data?.category)
        },
        error: (error) => {
          console.log('xxx', error.response)
        }
      }).setUrl('category/web-category-list/')
        .setMethod('GET')
        .send();
    }
  }


  const handleBack= ()=>{
    if (Object.keys(parent).length > 0 && mobileMenuOpen){
      getCategory()
    } else if (mobileMenuOpen) {
      setMobileMenuOpen(false)
    // } else if (history.location.pathname.includes('/list/')) {
    //   history.push(props.scrollPosition.location, props.scrollPosition.scrollPosition)
    //   props.setScrollPosition({})
    // } else if (history.location.pathname.includes('/post/') && props.scrollPosition2.location) {
    //   history.push(props.scrollPosition2.location, props.scrollPosition2.scrollPosition)
    //   props.setScrollPosition2({})
    } else {
      history.goBack()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={()=> history.push('/profile')}>
          صفحه شخصی
        </span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={props.logout}>
          خروج
        </span>
      </Menu.Item>

    </Menu>
  );
  const showBackButton = window.innerWidth < 576
    && (window.location.pathname !== ('/') && !window.location.pathname.includes('/favorite') && !window.location.pathname.includes('/profile') || mobileMenuOpen)
  const magziActive = !window.location.pathname.includes('favorite') && !window.location.pathname.includes('profile')
  return (
    <>
    <div id="search" style={ mobileMode && mobileMenuOpen ? {height: '100vh'} : mobileMode ? {height: '60px', overflow: "hidden"}: {height: 'auto'} }>
      <div className={`search-container ${!showBackButton ? 'hideBackButton' : ''}`} >
        {showBackButton ? <RightOutlined className="back-icon" onClick={handleBack} /> : null}
        <img className="logo" alt="magzi" src={images.magziBrand} onClick={() => {history.push('/'); window.location.href='/';} }/>

        <Input
          ref={inputRef}
          placeholder="هر آنچه می‌خواهید را جستجو کنید"
          value={value}
          allowClear
          suffix={ focus && value.length > 0 ?
            <img onClick={() => setValue('')} src={images.close} className="search-icon"/>
            : <SearchOutlined  onClick={() => onSearch(value, 0,)} className="search-icon" /> }
          onFocus={()=> {
            setFocus(true)
            setMobileMenuOpen(true)
          }}
          onBlur={()=> setTimeout(()=> setFocus(false), 300)}
          onChange={(value) => {
            setFocus(true)
            setValue(value.target.value)
          }}
          onPressEnter={() => onSearch(value, 0,)}
          style={{width: '100%'}}
        />
        <div className="auth">
          {auth.status?
            <span>
              <Dropdown overlay={menu} placement="bottomCenter">
                <UserOutlined />
              </Dropdown>
            <HeartOutlined onClick={()=> history.push('/favorite')} />
          </span>
          : <span className="flex-center">
            <span className="link-text" onClick={()=> setShowLogin(true)}>ورود </span>
            <span className="mx-10">{' / '}</span>
            <span className="link-text" onClick={()=> setShowSignUp(true)}> ثبت نام</span>
          </span>
          }
        </div>
      </div>
      {!mobileMode ?
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        className="main-menu"
      >
        {
          category.map((cat: any) =>
            <SubMenu
              popupClassName={cat.sub_category.length === 0 ? 'no-children' : ''}
              key={cat.parent_category.parent_category_name}
              title={cat.parent_category.parent_category_name}
              onTitleClick={() => onSearch(cat.parent_category.parent_category_name)}
            >
              {<Menu.Item key={cat.parent_category.parent_category_name} onClick={() => onSearch(cat.parent_category.parent_category_name)}>مشاهده همه</Menu.Item>}
              <Divider style={{margin: 0}} />
              {
                 cat.sub_category?.map((sub2: any) =>
                  <Menu.ItemGroup
                    className={sub2.sub_category?.length > 0 ? "have_sub_category" : ""}
                    title={
                      <>
                        <span onClick={() => onSearch(sub2.parent_category)}>{sub2.parent_category}</span>
                        {sub2.sub_category?.length > 0 ? <LeftOutlined /> : null}
                      </>
                    }
                  >
                    {<Menu.Item key={sub2.parent_category} onClick={() => onSearch(sub2.parent_category)}>مشاهده همه</Menu.Item>}
                    <Divider style={{margin: 0}} />
                    {
                      sub2.sub_category?.length> 0 &&  sub2.sub_category.map((sub3: string) =>
                        <Menu.Item key={sub3} onClick={() => onSearch(sub3)}>{sub3}</Menu.Item>
                      )
                    }
                  </Menu.ItemGroup>
                )
              }
            </SubMenu>
          )
        }
      </Menu> :
        <>
          {parent &&
          <div className="parent-container">
            <h3>{parent?.parent_category_name || parent?.category_name || 'دسته‌بندی‌ محصولات'}</h3>
            {(parent?.parent_category_name || parent?.category_name) &&
            <span onClick={()=>onSearch(parent?.parent_category_name || parent?.category_name)}>مشاهده همه</span>
            }
          </div>
          }
          <Menu
            mode="inline"
          >
            {mobileCategory.map((item:any) =>
              <Menu.Item
                key={item.id || item.category_name}
                className="flex-between"
                onClick={() => {
                  if (item.have_sub_category){
                    handleClickMobile(item)
                  } else {
                    onSearch(item.parent_category_name || item.category_name)
                  }
                }}
              >
                <span>
                  {item.parent_category_name || item.category_name}
                </span>
                {item.have_sub_category ? categoryLoading === item.id ? <LoadingOutlined /> : <LeftOutlined /> : null }
              </Menu.Item>
            )}
          </Menu>
          {!mobileMenuOpen &&
          <div className="navbar-mobile">
            <img
              onClick={()=> {
                history.push('/')
                window.scrollTo(0, 0);
              }}
              src={magziActive ? images.magziBrand :images.magziBrandGray}
            />
            <HeartOutlined
              style={window.location.pathname === "/favorite" ? {color: '#ff5252'} : {}}
              onClick={()=> history.push('/favorite')}
            />
            <UserOutlined
              style={window.location.pathname === "/profile" ? {color: '#ff5252'} : {}}
              onClick={()=> history.push('/profile')}
            />

          </div>
          }
        </>

      }
    </div>


      </>
  );

}

export default MainSearch;
