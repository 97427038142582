import React, {useState} from 'react';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import Ajax from "../../../api/ajax";
import {useDispatch} from "react-redux";
import {setAuth} from "../../../store/reducer/authSlice.js";
import {p2e} from "../../../utill/p2e"
import * as images from "../../../assets/image";

function Login(props:any) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    const data = {username: p2e(values.username), password: p2e(values.password)}
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        props.setShow(false)
        dispatch(setAuth({status: true , token: res.data.data?.token  }))
        setFormError('')
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/login/')
      .setMethod('POST')
      .setData(data)
      .send();
  };

  const goReset = ()=> {
    props.setShow(false)
    props.setShowReset(true)
  }

  const title = <span>
            <span style={{color: '#ff5252'}}>ورود </span>
            <span className="mx-15">{' / '}</span>
            <span
              style={{cursor: "pointer"}}
              onClick={()=> {
              props.setShowSignUp(true)
              props.setShow(false)
            }}>
              ثبت نام
            </span>
            </span>

  return (
    <Modal
      title={title}
      footer={null}
      width={400}
      visible={props.show}
      onCancel={()=> props.setShow(false)}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label="موبایل"
          name="username"
          rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="رمز عبور"
          name="password"
          rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
        >
          <Input.Password
            iconRender={visible => (visible ? <img src={images.closeEye} /> : <img src={images.closeEye} />)}
          />
        </Form.Item>
        <div className="my-10 flex-end">
          <span className="link-text" onClick={goReset}> بازیابی رمز عبور؟</span>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
             ورود به مگ‌زی
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default Login;
