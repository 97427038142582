import React, {useEffect, useState} from 'react';
import './style.scss'
import Head from "./modules/Head"
import Sec1 from "./modules/Sec1"
import Ajax from "../../api/ajax";
import {useSelector} from "react-redux";
import {authReducer} from "../../store/reducer/authSlice";
import LazyLoad from "react-lazyload";



function HomeMobile(props: any) {
  const {auth} = useSelector(authReducer);
  const [items, setItems] = useState([]);


  const handleScroll = () => {
    if (window.location.pathname === '/') {
      const position = window.pageYOffset;
      props.setScrollPosition(position);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const scrollTo = () => {
    if (props.scrollPosition) {
      window.scrollTo(0, props.scrollPosition);
      props.setScrollPosition(0)
    } else {
      window.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    getCollection(props.title)
  }, []);


  const getCollection = (name: string) => {
    new Ajax({
      success: (res) => {
        setItems(res.data.collection_list)
        scrollTo()
      },
      error: (error) => {

      }
    }).setUrl('collection/get-collections-test/')
      .setMethod('GET')
      .send();
  }

  return (
    <div id="HomeMobile" className="relative">

      {!auth.status && items.length > 0 && <Head setShowLogin={props.setShowLogin} setShowSignUp={props.setShowSignUp}/>}
      {items.length > 0 && items.map((sec: any, index) =>
        <LazyLoad height={200} offset={0} once>
        <Sec1 onSearch={props.onSearch} setShowLogin={props.setShowLogin} sec={sec} />
        </LazyLoad>
      )}

    </div>
  );
}

export default HomeMobile;
