import React from 'react';


function NotFound() {
  return (
    <h1>NotFound</h1>
  );
}

export default NotFound;
