import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Alert} from "antd";
import Ajax from "../../../api/ajax";
import * as images from "../../../assets/image";
import {p2e} from "../../../utill/p2e";


function SignUp(props:any) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    form.setFieldsValue(props.userInfoCatch)
  }, [props.userInfoCatch]);

  const onFinish = (values: any) => {
    const data = {
       password: p2e(values.password),
       password2: p2e(values.password2), 
       username: p2e(values.phone_number),
       phone_number: p2e(values.phone_number)
      }
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        //props.setUserInfoCatch(values)
        props.setUserInfoCatch(data)
        props.setShow(false)
        props.setShowVerify(true)
        setFormError('')
        form.resetFields()
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/registration/')
      .setMethod('POST')
      .setData(data)
      .send();
  };

  const title = <span className="title">
            <span
              style={{cursor: "pointer"}}
              onClick={()=> {
              props.setShowLogin(true)
              props.setShow(false)
            }}>ورود
            </span>
            <span className="mx-15">{' / '}</span>
            <span  style={{color: '#ff5252'}}>ثبت نام</span>
          </span>

  return (
    <Modal
      title={title}
      footer={null}
      width={400}
      visible={props.show}
      onCancel={()=> props.setShow(false)}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >

        <Form.Item
          label=" شماره همراه"
          name="phone_number"
          rules={[
            {required: true, message: 'لطفا این فیلد را پر کنید!' },
            {pattern: /^[0-9]+$/g, message: 'شماره تلفن نا معتبر است'}
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="رمز عبور"
          name="password"
          rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
        >
          <Input.Password
            iconRender={visible => (visible ? <img src={images.closeEye} /> : <img src={images.closeEye} />)}
          />
        </Form.Item>
        <Form.Item
          label="تکرار رمز عبور"
          name="password2"
          rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
        >
          <Input.Password
            iconRender={visible => (visible ? <img src={images.closeEye} /> : <img src={images.closeEye} />)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            تایید
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}


export default SignUp;
