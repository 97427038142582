import React, {useEffect, useState} from 'react';
import { Layout} from 'antd';
import MainSearch from '../../components/search/search';
import SearchList from '../searchList/searchList';
import Home from '../home/home';
import HomeMobile from '../homeMobile';
import Footer from "../../components/footer";
import './style.scss';
import Ajax from "../../api/ajax";
import {Route, useHistory, withRouter, Switch} from 'react-router-dom';
import Login from "../../components/auth/login";
import SignUp from "../../components/auth/signUp";
import VerifyCode from "../../components/auth/verifyCode";
import ResetPassword from "../../components/auth/resetPassword";

const PostDetail = React.lazy(() => import ('../postDetail/postDetail'))
const Profile = React.lazy(() => import ('../profile'))
const PublisherProfile = React.lazy(() => import ('../publisherProfile'))
const Favorite = React.lazy(() => import ('../favorite'))

const {Header, Content} = Layout;


function Landing() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [word, setWord] = useState(localStorage.getItem('word') || '');
  const [type, setType] = useState(localStorage.getItem('type') || 0);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPosition2, setScrollPosition2] = useState(0);
  const [userInfoCatch, setUserInfoCatch] = useState(false);
  const [message, setMessage] = useState('');


  useEffect(() => {
    const params:any = history.location.pathname.split('/')[2]?.split('-')
    if (history.location.pathname.includes('/list') && params[0]) {
      onSearch2(params[0], parseInt(params[1]), parseInt(params[2]))
    } else if (history.location.pathname === '/list') {
      history.push('/')
    }

    if (history.location.pathname === '/' || history.location.pathname === '/favorite' || history.location.pathname === '/profile'  ) {
      catchWord('')
    }

    setShowFooter(!window.location.pathname.includes('/favorite') && !window.location.pathname.includes('/profile'))
  }, [window.location.pathname]);

  const catchWord = (value:any)=>{
    localStorage.setItem('word', value);
    setWord(value)
  }
  const catchType = (value:any)=>{
    localStorage.setItem('type', value);
    setType(value)
  }

  const onSearch = (_name: string = word, _offset: number = offset, _type: string|number = type, _ScrollPosition?:any) => {
    history.push(`/list/${_name}-${_offset}-${_type}`)
  }

  const onSearch2 = (_name: string = word, _offset: number = offset, _type: string|number = type) => {
    setLoading(true)

    if (_name === localStorage.getItem('word') && _offset === offset) {
      setTimeout(()=>window.scrollTo(0, scrollPosition2), 1500)
    } else {
      window.scrollTo(0, 0)
    }
    if (_name) {
      catchWord(_name)
    }
    if (_type) {
      catchType(_type)
    }
    new Ajax({
      success: (res) => {
        setOffset(_offset)
        setList(res.data.data.posts)
        setTotal(res.data.data.match_count)
        setLoading(false)
        setMessage('')
      },
      error: (error) => {
        setList([])
        setLoading(false)
      }
    }).setUrl(_type === 1 ? `collection/get-collection-by-parentcollection/see_more/collection=${_name}&limit=${60}&offset=${_offset * 60}/`
      : _type === 2 ?`publisher/final-offer/limit=${60}&offset=${_offset * 60}/`
        : _type === 3 ?`post/favorite-post/limit=${60}&offset=${_offset * 60}/`
          : `post/search/query=${_name}&limit=${60}&offset=${_offset * 60}/`)
      .setMethod('GET')
      .setPrivate()
      .send();
  }

  const logout = ()=>{
    new Ajax({
      success: (res) => {
        localStorage.clear()
        window.location.replace('/')
      },
      error: (error) => {
      }
    }).setUrl('dj-rest-auth/logout/').setPrivate().setMethod('POST').send();
  }


  return (
    <Layout>

      <Header>
        <MainSearch
          word={word}
          setOffset={setOffset}
          onSearch={onSearch}
          setList={setList}
          setTotal={setTotal}
          setType={catchType}
          setShowLogin={setShowLogin}
          setShowSignUp={setShowSignUp}
          logout={logout}
        />
      </Header>
      <Content>
        <div style={{display: window.location.pathname === '/' ? 'block' : 'none'}}>
          {window.innerWidth > 1000 ?
            <Home
              loading={loading}
              list={list}
              onSearch={onSearch}
              setShowLogin={setShowLogin}
              setOffset={setOffset}
            />
            : <HomeMobile
              loading={loading}
              list={list}
              onSearch={onSearch}
              setShowLogin={setShowLogin}
              setShowSignUp={setShowSignUp}
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
            />
          }
        </div>

        <React.Suspense fallback={<div />}>
        <Switch>
          <Route path={`/list/:name`} exact>
            <SearchList
              loading={loading}
              list={list}
              total={total}
              setOffset={setOffset}
              offset={offset}
              onSearch={onSearch}
              setShowLogin={setShowLogin}
              message={message}
              scrollPosition2={scrollPosition2}
              setScrollPosition2={setScrollPosition2}
            />
          </Route>
          <Route path={`/post/:id`}>
            <PostDetail setShowLogin={setShowLogin} />
          </Route>
          <Route path={`/publisher/:id`}>
            <PublisherProfile setShowLogin={setShowLogin} />
          </Route>
          <Route path={`/favorite`}>
            <Favorite setShowLogin={setShowLogin} setShowSignUp={setShowSignUp} />
          </Route>
          <Route path={`/profile`}>
            <Profile setShowLogin={setShowLogin} setShowSignUp={setShowSignUp} logout={logout} />
          </Route>
        </Switch>
        </React.Suspense>
        <Login
          show={showLogin}
          setShow={setShowLogin}
          setShowSignUp={setShowSignUp}
          setShowReset={setShowReset}
        />
        <SignUp
          show={showSignUp}
          setShow={setShowSignUp}
          setShowLogin={setShowLogin}
          setShowVerify={setShowVerify}
          userInfoCatch={userInfoCatch}
          setUserInfoCatch={setUserInfoCatch}
        />
        <VerifyCode
          show={showVerify}
          setShow={setShowVerify}
          setShowSignUp={setShowSignUp}
          userInfoCatch={userInfoCatch}
        />
        {showReset &&
        <ResetPassword
          show={showReset}
          setShow={setShowReset}
        />
        }
      </Content>
      <Footer show={showFooter}/>

    </Layout>
  );
}

export default withRouter(Landing);
