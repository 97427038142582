import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import {MODE} from "../constants";
import {combineReducers} from 'redux';
import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage';
import authReducer from './reducer/authSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}
const rootReducer = combineReducers({authReducer})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = MODE === 'production' ? configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
  })
  : configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(logger)
  })
export default store