import React, {useEffect, useState} from 'react';

import './style.scss'
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import ImageItem from "../../../../components/imageItem/imageItem";
import Ajax from "../../../../api/ajax";
import {Collection_API} from "../../../../api/constant";
import {CaretLeftOutlined} from "@ant-design/icons";
import SwiperCarousel from "../../../../components/swiper";
import {SwiperSlide} from "swiper/react";

function ForGift(props: any) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getCollection('مناسب هدیه')
  }, []);

  const getCollection = (name: string) => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setItems(res.data.data.posts)
      },
      error: (error) => {
        setLoading(false)
      }
    }).setUrl(Collection_API(name, 6))
      .setMethod('GET')
      .send();
  }

  return (
    <div id="sample" className="relative">
      <FullPageLoading show={loading}/>
      <div className="home-section">
        <h2 className="home-title">مناسب هدیه</h2>
        <SwiperCarousel>
          {items.map((item) =>
            <SwiperSlide className="category-item">
              <div><ImageItem setPost={props.setPost} item={item} setShowLogin={props.setShowLogin} /></div>
            </SwiperSlide>
          )}
        </SwiperCarousel>
        <span
          className="see-more"
          onClick={()=>{
            props.onSearch('مناسب هدیه', 0, 2)
          }}
        >
          بیشتر ببینید <CaretLeftOutlined />
        </span>
      </div>
    </div>
  );
}

export default ForGift;
