import React, {useEffect, useState} from 'react';
import * as images from '../../../../assets/image'
import './style.scss'
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import {useSelector} from "react-redux";
import {authReducer} from "../../../../store/reducer/authSlice";
import {Button} from "antd";



function Head(props: any) {
  const [loading, setLoading] = useState(false);
  const {auth} = useSelector(authReducer);

  return (
    <div id="Head" className="relative">
      <FullPageLoading show={loading}/>

      <div className="home-section" style={{background: '#dcfdf9'}}>
        {auth.status ?
          <>
            <img className="head-logo" src={images.magze_fa_red}/>
            <h2 className="home-title">مجله گالری زندگی</h2>
          </> :
          <div className="not_auth">
            <h2 className="home-title">{'مجموعه دست‌سازهای ایرانی'}</h2>
            <h2 className="home-title">{' از سازنده‌های مستقل'}</h2>
            <p style={{marginTop: 25}}>برای شخصی‌سازی پیشنهادها وارد شوید</p>
            <Button className="btn-primary" onClick={() => props.setShowLogin(true)}>
              ورود
            </Button>
            <h4 onClick={()=> props.setShowSignUp(true)}>حساب کاربری ندارم</h4>
          </div>
        }
      </div>

    </div>
  );
}

export default Head;
