import axios from 'axios';
import * as constants from './constant';
import {setAuth} from '../store/reducer/authSlice'
import store from "../store/store";
import Notification from "../components/Notification/Notification";
interface params {
  success: (res: any) => void,
  error: (error: any) => void,
}

export default class Ajax {
  data: object = {};
  method: string = "GET";
  private: boolean = false;
  url: string = "";
  success: (res: any) => void;
  error: (error: any) => void;

  constructor(params: params) {
    this.success = (res) => {
      if (res?.data?.message && res?.data?.message !== 'OK' && this.method !== "GET"){
        Notification('success', 'موفق', res?.data?.message)
      }
      if (params.success) {
        params.success(res);
      }
    };
    this.error = (error) => {
      if (! error.response) {
        if (navigator.onLine) {
          Notification('error', 'خطا', 'مشکلی در اتصال به سرور وجود دارد');
        }
      } else if (error.response?.data?.message){
        Notification('error', 'خطا', error.response.data?.message)
      }
      if (error.response?.status === 401){
        store.dispatch(setAuth({status: false , token: ''  }))
      }
      if (params.error) {
        params.error(error);
      }
    };
  }

  send(): void {
    const token = ()=> this.private ? (store.getState().authReducer.auth?.token) : null
    const header = this.private && token() ? {
      'Accept': 'application/json',
      'Authorization': `Token ${token()}`
    } : {
      'Accept': 'application/json',
    }

    const options: object = {
      url: constants.baseUrl + this.url,
      method: this.method,
      headers: header,
      data: this.data,
    };
    axios(options).then((this.success)).catch(this.error);
  }

  setMethod(method: string): this {
    this.method = method;
    return this;
  }

  setUrl(url: string): this {
    this.url = url;
    return this;
  }

  setPrivate(): this {
    this.private = true;
    return this;
  }

  setData(data: object): this {
    this.data = data;
    return this;
  }
}
