import React, {useEffect, useState} from 'react';
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import ImageItem from "../../../../components/imageItem/imageItem";
import SwiperCarousel from "../../../../components/swiper";
import { SwiperSlide } from "swiper/react";
import LazyLoad from "react-lazyload";
import './style.scss'
import Ajax from "../../../../api/ajax";
import {Collection_API} from "../../../../api/constant";
import Loading from "../../../../components/Loading";

function Sec1(props: any) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getCollection(props.sec.collection)
  }, []);

  const getCollection = (name: string) => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setItems(res.data.data.posts)
      },
      error: (error) => {
        setLoading(false)
      }
    }).setUrl(Collection_API(name, 10))
      .setMethod('GET')
      .send();
  }


  return (
    <div id={`sec${props.index}`} style={{minHeight: 230, position: "relative"}} >
      <Loading loading={loading} type="inline" style={{display: 'none'}}/>
      <div className="home-section" style={{ direction: "rtl" }}>
        <div className="flex-between">
          <h2 className="home-title">{props.sec.collection}</h2>
              <span
                className="see-more"
                onClick={()=>{
                  props.onSearch(props.sec.collection, 0, 1)
                }}
              >
          مشاهده بیشتر
        </span>
        </div>

        <SwiperCarousel>
          {items.map((item:any) =>
            <SwiperSlide>
              <LazyLoad once>
                <ImageItem setPost={props.setPost} item={item} setShowLogin={props.setShowLogin} />
              </LazyLoad>
            </SwiperSlide>
          )}
        </SwiperCarousel>


      </div>
    </div>
  );
}

export default Sec1;
