import {notification} from "antd";
import "./style.scss";
const NF:any=notification
const Notification = (type: string, message: string, description: string) => {
  NF[type]({
    message: message,
    description: description,
    duration: 5,
    maxCount:3,
    placement: 'topLeft',
  });
};

export default Notification;
