import React, {useState} from 'react';
import {Alert, Button, Form, Input, Modal} from "antd";
import Ajax from "../../../api/ajax";
import {setAuth} from "../../../store/reducer/authSlice";
import {useDispatch} from "react-redux";


function ResetPassword(props:any) {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [formError, setFormError] = useState('');

  const onReset = (values: any) => {
    setLoading(true)
    setPhoneNumber(values.phone_number)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setStep(2)
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/password-reset/')
      .setMethod('POST')
      .setData(values)
      .send();
  };

  const onConfirm = (values:any)=> {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        dispatch(setAuth({status: true , token: res.data.data?.token  }))
        setStep(3)
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/password-reset-verify/')
      .setMethod('POST')
      .setData({key: values.key})
      .send();
  };



  const onFinish =(values:any) => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setStep(0)
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/password-reset-confirm/')
      .setMethod('POST')
      .setData(values)
      .setPrivate()
      .send();
  }


  return (
    <Modal
      title={'بازیابی رمز عبور'}
      footer={null}
      width={400}
      visible={props.show}
      onCancel={()=> props.setShow(false)}
    >
      {step === 1 ?
        <Form
          name="basic"

          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onReset}
        >
          {formError && <Alert message={formError} type="error" showIcon />}
          <Form.Item
            label=" شماره همراه"
            name="phone_number"
            rules={[
              {required: true, message: 'لطفا این فیلد را پر کنید!' },
              {pattern: /^[0-9]+$/g, message: 'شماره تلفن نا معتبر است'}
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              تایید
            </Button>
          </Form.Item>
        </Form>
        : step === 2 ?
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onConfirm}
        >
          {formError && <Alert message={formError} type="error" showIcon />}
          <Form.Item
            label={` کد ارسال شده به شماره موبایل ${phoneNumber}  را وارد کنید`}
            name="key"
            rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              تایید
            </Button>
          </Form.Item>
        </Form>
          :
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {formError && <Alert message={formError} type="error" showIcon />}

            <Form.Item
              label="رمز عبور جدید"
              name="new_password1"
              rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="تکرار رمز عبور جدید"
              name="new_password2"
              rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                تایید
              </Button>
            </Form.Item>
          </Form>
      }

    </Modal>
  );
}

export default ResetPassword;
