import React, {useEffect, useState} from 'react';
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import Ajax from "../../../../api/ajax";
import ImageItem from "../../../../components/imageItem/imageItem";
import './style.scss'
import {CaretLeftOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import SwiperCarousel from "../../../../components/swiper";
import {SwiperSlide} from "swiper/react";

function Favorites(props: any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);


  useEffect(() => {
    getCollection()
  }, []);

  const getCollection = () => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        setItems(res.data.data.posts)
      },
      error: (error) => {
        setLoading(false)
      }
    }).setUrl(`post/favorite-post/limit=${6}&offset=${0}/`)
      .setMethod('GET')
      .setPrivate()
      .send();
  }


  return (
    items.length >0 ?
    <div id="Favorites" className="relative">
      <FullPageLoading show={loading}/>
      <div className="home-section">
        <h2 className="home-title">علاقه‌مندی‌ها</h2>
        <SwiperCarousel>
          {items.map((item) =>
            <SwiperSlide className="category-item">
              <div><ImageItem setPost={props.setPost} item={item} setShowLogin={props.setShowLogin} /></div>
            </SwiperSlide>
          )}
        </SwiperCarousel>
        <span
          className="see-more"
          onClick={()=>{
            history.push('/favorite')
          }}
        >
          بیشتر ببینید <CaretLeftOutlined />
        </span>
      </div>
    </div> : null
  );
}

export default Favorites;
