import React, {useState} from 'react';
import {Button, Form, Input, Modal, Alert} from "antd";
import Ajax from "../../../api/ajax";


function VerifyCode(props:any) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const onFinish = (values: any) => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        props.setShow(false)
        setFormError('')
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/registration/verify-phone-number/')
      .setMethod('POST')
      .setData(values)
      .send();
  };

  const goSignup = () => {
    props.setShowSignUp(true);
    props.setShow(false)
  }

  const resend = () => {
    setLoading(true)
    new Ajax({
      success: (res) => {
        setLoading(false)
        form.resetFields()
      },
      error: (error) => {
        setLoading(false)
        setFormError(error.response?.message)
      }
    }).setUrl('dj-rest-auth/registration/')
      .setMethod('POST')
      .setData(props.userInfoCatch)
      .send();
  }

  const title = <span>
            <span
              style={{cursor: "pointer"}}
              onClick={()=> {
                props.setShowLogin(true)
                props.setShow(false)
              }}>ورود
            </span>
            <span className="mx-15">{' / '}</span>
            <span  style={{color: '#ff5252'}}>ثبت نام</span>
          </span>

  return (
    <Modal
      title={title}
      footer={null}
      width={400}
      visible={props.show}
      onCancel={()=> props.setShow(false)}
    >
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {formError && <Alert message={formError} type="error" showIcon />}
        <Form.Item
          label={`کد ارسال شده به شماره موبایل ${props.userInfoCatch?.phone_number} را وارد کنید`}
          name="key"
          rules={[{ required: true, message: 'لطفا این فیلد را پر کنید!' }]}
        >
          <Input />
        </Form.Item>

        <div className="my-10 flex-between">
          <span className="link-text2" onClick={goSignup}>تغییر شماره همراه</span>
          <span className="link-text2" onClick={resend}> ارسال مجدد</span>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            تایید
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}


export default VerifyCode;
