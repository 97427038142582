import React from 'react';
import AppRooter from './router/AppRouter';
import {ConfigProvider} from 'antd';


function App() {
  return (
    <ConfigProvider direction="rtl">
      <AppRooter/>
    </ConfigProvider>
  );
}

export default App;
