import React from "react";
import './style.scss';
import {LoadingOutlined} from "@ant-design/icons";


function FullPageLoading(props: any) {

  return (
    <>
      {props.show
        ? <div className="full-page-loading">
          {props.spinner &&
          <div className="spinner-container">
              <div style={{width: 50, fontSize: 50}}>
                  <LoadingOutlined style={{color: '#ff5252'}}/>
              </div>
          </div>
          }
        </div> : null
      }
    </>

  );

}

export default FullPageLoading;
