import React from 'react';
import './style.scss'
import * as images from './images'
import SwiperCarousel from "../../../../components/swiper";
import {SwiperSlide} from "swiper/react";

const items = [
  {name: 'بهاره', img: images.img1 },
  {name: 'اکسسوری', img: images.img2 },
  {name: 'چیدمان', img: images.img3 },
  {name: 'دکوری', img: images.img4},
  {name: 'صبحانه', img: images.img5 },
  {name: ' گیاه آپارتمانی', img: images.img6 },
]

function IranianHandMade(props: any) {


  return (
    <div id="IranianHandMade" className="relative">
      <div className="home-section" style={{background: '#dcfdf9'}}>
        <h2 className="home-title">مجموعه دست‌ساز‌های ایرانی، از سازنده‌های مستقل</h2>

        <SwiperCarousel>
          {items.map((item:any) =>
            <SwiperSlide className="category-item">
              <div className="category-item">
                <img
                  src={item.img}
                  className="image"
                  onClick={()=>{
                    props.onSearch(item.name, 0, 1)
                  }}
                />
                <h4>{item.name}</h4>
              </div>
            </SwiperSlide>
          )}
        </SwiperCarousel>
      </div>

    </div>
  );
}

export default IranianHandMade;
