import {createSlice} from '@reduxjs/toolkit';



export const authSlice = createSlice({
  name: 'User',
  initialState: {
    auth:{status: false, token:''}
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    logout: (state, action) => {
      state = undefined;
    },
  },
});

export const {setUserInfo, setAuth, logout} = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const UserInfoAsync = () => (dispatch, getState) => {

};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const authReducer = state => state.authReducer;

export default authSlice.reducer;
