import * as React from 'react'
import './style.scss'
import { Swiper, SwiperSlide } from "swiper/react";



interface IProps {

}

const SwiperCarousel: React.FC<IProps> = (props:any) => {
  return (
    <Swiper
      slidesPerView={2.5}
      breakpoints={{
        "640": {
          "slidesPerView": 2,
        },
        "768": {
          "slidesPerView": 4,
        },
        "1024": {
          "slidesPerView": 5,
        },
        "1300": {
          "slidesPerView": 6,
        }
      }}
      className="mySwiper"
    >
      {props.children}
    </Swiper>
  )
}

export default SwiperCarousel
