import React from 'react';
import './style.scss'
import FullPageLoading from "../../../../components/fullPageLoading/fullPageLoading";
import SwiperCarousel from "../../../../components/swiper";
import {SwiperSlide} from "swiper/react";
import * as images from "./images";


const items = [
  {name: 'خانم‌ها', img: images.img1 },
  {name: 'آقایان', img: images.img2 },
  {name: 'کودکان', img: images.img3 },
  {name: 'دوستداران حیوانات', img: images.img4},
  {name: 'دوستداران طبیعت', img: images.img5 },
  {name: 'گیاهخواران', img: images.img6 },
]
function GoodFor(props: any) {
  return (
    <div id="GoodFor" className="relative">
      <div className="home-section">
        <h2 className="home-title">مناسب برای</h2>
        <SwiperCarousel>
          {items.map((item:any) =>
            <SwiperSlide>
              <div className="category-item">
                <img
                  src={item.img}
                  className="image"
                  onClick={()=>{
                    props.onSearch(item.name, 0, 1)
                  }}
                />
                <h4>{item.name}</h4>
              </div>
            </SwiperSlide>
          )}
        </SwiperCarousel>
      </div>

    </div>
  );
}

export default GoodFor;
